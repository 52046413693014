<template>
   <div class="tab-pane fade show active" id="ngn" role="tabpanel" aria-labelledby="tabs-ngn-tabJustify">
      <div class="max-w-xl mx-auto py-10">
         <div class="border-b border-gray-100 pb-10">
            <div class="text-3xl text-gray-700 font-semibold">
               &#8358;{{ user.wallets.ngn_balance | formatPrice }}
            </div>
            <p class="text-sm text-gray-400 py-2">Available NGN Balance</p>
            <div class="mt-3 flex space-x-3">
               <div>
                  <button type="button" data-bs-toggle="modal" data-bs-target="#topUp" 
                     class="rounded py-1.5 transition px-4  text-sm text-center whitespace-nowrap bg-indigo-900 hover:bg-indigo-800 text-white cursor-pointer">
                     Top up balance
                  </button>
                  <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="topUp" 
                     data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="topUpTitle" aria-modal="true" aria-hidden="true" role="dialog">
                     <div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
                        <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                           <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 px-8 border-b border-gray-100 rounded-t-md">
                              <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalScrollableLabel">
                                 Top-up NGN wallet
                              </h5>
                              <button type="button" ref="Close" @click.prevent="isLoading = false; form.amount = ''; total_amount = ''"
                                 class="btn-close rounded-full bg-gray-200 box-content w-3 h-3 p-1 text-indigo-900 text-xs border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-indigo-900 hover:opacity-75 hover:no-underline"
                                 data-bs-dismiss="modal" aria-label="Close"></button>
                           </div>
                           <div class="modal-body relative p-8">
                              <ValidationObserver v-slot="{ handleSubmit }">
                                 <form @submit.prevent="handleSubmit(paystackPay)">
                                    <div class="bg-gray-50 rounded-md p-5 mb-5 text-sm text-gray-500">
                                       <strong>Note:</strong> You will be charged an extra transaction fee of N100 + 1.5% of your Naira deposit amount. 
                                    </div>
                                    <ValidationProvider tag="div" name="amount" class="mb-4" rules="required|double" v-slot="{ errors }">
                                       <label for="amount" class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Amount to fund (NGN) <span class="text-red-700">*</span></label>
                                       <input type="text" v-model="form.amount"
                                          class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                                          :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                                          autocomplete="off" spellcheck="false"/>
                                          <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                    <ValidationProvider tag="div" name="total amount" class="mb-4" rules="required|double" v-slot="{ errors }">
                                       <label for="amount" class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Total amount to pay (NGN) <span class="text-red-700">*</span></label>
                                       <input type="text" v-model="total_amount" disabled
                                          class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                                          :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                                          autocomplete="off" spellcheck="false"/>
                                          <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                    <div class="relative" v-if="form.amount == ''">
                                       <button class="inline-flex items-center cursor-pointer transition 
                                          ease-in-out duration-300 font-semibold font-body text-center justify-center border-0 border-transparent rounded-md 
                                          focus-visible:outline-none focus:outline-none focus:bg-opacity-80 bg-heading text-white px-5 py-3 md:px-6 bg-indigo-900 
                                          hover:bg-indigo-800 w-full mt-1.5" type="submit">
                                          <svg v-if="isLoading" class="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                             <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                             <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                          </svg> Proceed 
                                       </button>
                                    </div>
                                    <div class="relative" v-else>
                                       <Paystack class="w-full"
                                          :amount="amount"
                                          :email="user.email"
                                          :paystackkey="form.paystackkey"
                                          :reference="reference"
                                          :callback="processPayment"
                                          :channels="form.channels"
                                          :firstname="user.account.first_name | capitalize"
                                          :lastname="user.account.last_name | capitalize"
                                          :close="close">
                                             <button class="inline-flex items-center cursor-pointer transition 
                                             ease-in-out duration-300 font-semibold font-body text-center justify-center border-0 border-transparent rounded-md 
                                             focus-visible:outline-none focus:outline-none focus:bg-opacity-80 bg-heading text-white px-5 py-3 md:px-6 bg-indigo-900 
                                             hover:bg-indigo-800 w-full mt-1.5" type="submit">
                                             <svg v-if="isLoading" class="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                             </svg> Proceed 
                                          </button>
                                       </Paystack>
                                    </div>
                                 </form>
                              </ValidationObserver>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div>
                  <div class="rounded py-1.5 transition px-4  text-sm text-center whitespace-nowrap bg-indigo-900 hover:bg-indigo-800 text-white cursor-pointer">
                     Withdraw
                  </div>
               </div>
            </div>
         </div>
         <div class="pt-8">
            <div class="grid grid-cols-3 gap-5">
               <div>
                  <div class="border rounded py-2 text-center whitespace-nowrap bg-indigo-100 border-indigo-200 hover:bg-indigo-200 hover:border-indigo-300 cursor-pointer">
                     Personal Bank Details
                  </div>
               </div>
               <div>
                  <div class="border rounded py-2 text-center whitespace-nowrap bg-indigo-100 border-indigo-200 hover:bg-indigo-200 hover:border-indigo-300 cursor-pointer">
                     Business Bank Details
                  </div>
               </div>
               <div>
                  <div class="border rounded py-2 text-center whitespace-nowrap bg-indigo-100 border-indigo-200 hover:bg-indigo-200 hover:border-indigo-300 cursor-pointer">
                     Affiliate Earnings
                  </div>
               </div>
               <div></div>

            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
   components: {
      
   },

   data() {
      return {
         form: {
            amount: '',
            paystackkey: process.env.VUE_APP_PAYSTACK_PUBLIC_KEY,
            currency: 'NGN',
            channels: ['card', 'bank', 'ussd'],
            transaction_ref: '',
            charge: 0,
         },
         isLoading: false
      }
   },

   computed: {
      ...mapGetters('auth', ['user']),
      
      reference() {
         const d = new Date();
         let seconds = d.getSeconds();
         let day = d.getDate();
         let month = d.getMonth() + 1;
         let year = (d.getFullYear() + '').slice(-2);

         let ref = 'TX-NGN-' + seconds + day + month + year

         return ref;
      },

      total_amount: function() {
         if(this.form.amount == '') {
            return this.form.amount
         } else {
            let percent =  (0.015 * parseFloat(this.form.amount)) + 100 // 1.5% by amount
            let fee = percent + parseFloat(this.form.amount)
            
            return parseFloat(fee.toFixed(2))
         }
      },

      amount: function() {
         return parseFloat((this.total_amount * 100).toFixed(2))
      }
   },

   methods: {
      ...mapActions('wallets', ['top_up_ngn']),
      ...mapActions('auth', ['getUserData']),

      async paystackPay() {
         this.form.fullname = this.user.account.first_name + " " + this.user.account.first_name
      },

      async processPayment() {
         this.form.charge = this.total_amount - this.form.amount
         const endpoint_params = {
            amount: this.form.amount,
            charge: this.form.charge,
            gateway: "paystack",
            status: 'success',
         }

         try{
            let res = await this.top_up_ngn(endpoint_params)
            
            this.getUserData()
            this.$forceUpdate();
            this.$refs.Close.click();
            this.$toast.success(res.message)
         } catch(error) {
            this.$toast.error(error.response.data.message)
         }
      },

      close() {
         
      }
   }
}
</script>