<template>
   <div class="p-10">
      <section>
         <div class="text-base my-5">
            <div class="pt-5">
               <ul class="nav nav-tabs nav-justified flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0" id="tabs-tabJustify" role="tablist">
                  <li class="nav-item flex-grow text-center" role="presentation" @click="selectedWallet = 'ngn'">
                     <a href="#ngn" class="nav-link w-1/2 mx-auto block flex space-x-3 justify-center font-semibold text-sm leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent
                        px-6 py-4 mt-2 hover:border-transparent hover:text-indigo-700 focus:border-transparent active" id="tabs-ngn-tabJustify" data-bs-toggle="pill" data-bs-target="#ngn" role="tab"
                        aria-controls="ngn" aria-selected="true">
                        <img :src="require('@/assets/images/currency/nigeria.png')" alt="" class="w-5 h-5">
                        <span class="self-center">NGN</span>
                     </a>
                  </li>
                  <li class="nav-item flex-grow text-center" role="presentation" @click="selectedWallet = 'usd'">
                     <a href="#usd" class="nav-link w-1/2 mx-auto block flex space-x-3 justify-center font-semibold text-sm leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent
                        px-6 py-4 mt-2 hover:border-transparent hover:text-indigo-700 focus:border-transparent" id="tabs-messages-tabJustify" data-bs-toggle="pill" data-bs-target="#usd" role="tab"
                        aria-controls="usd" aria-selected="false">
                        <img :src="require('@/assets/images/currency/united-states.png')" alt="" class="w-5 h-5">
                        <span class="self-center">USD</span>
                     </a>
                  </li>
                  <li class="nav-item flex-grow text-center" role="presentation" @click="selectedWallet = 'cny'">
                     <a href="#cny" class="nav-link w-1/2 mx-auto block flex space-x-3 justify-center font-semibold text-sm leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent
                        px-6 py-4 mt-2 hover:border-transparent hover:text-indigo-700 focus:border-transparent" id="tabs-messages-tabJustify" data-bs-toggle="pill" data-bs-target="#cny" role="tab"
                        aria-controls="cny" aria-selected="false">
                        <img :src="require('@/assets/images/currency/china.png')" alt="" class="w-5 h-5">
                        <span class="self-center">CNY</span>
                     </a>
                  </li>
                  <li class="nav-item flex-grow text-center" role="presentation" @click="selectedWallet = 'usdt'">
                     <a href="#crypto" class="nav-link w-1/2 mx-auto block flex space-x-3 justify-center font-semibold text-sm leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent
                        px-6 py-4 mt-2 hover:border-transparent hover:text-indigo-700 focus:border-transparent" id="tabs-messages-tabJustify" data-bs-toggle="pill" data-bs-target="#crypto" role="tab"
                        aria-controls="crypto" aria-selected="false">
                        <img :src="require('@/assets/images/currency/bitcoin.png')" alt="" class="w-5 h-5">
                        <span class="self-center">CRYPTO</span>
                     </a>
                  </li>
               </ul>
            </div>
            <div class="absolute w-full relative">
               <div class="bg-white rounded-lg shadow p-8 -mt-2">
                  <div class="tab-content" id="tabs-tabContentJustify">
                     <NgnWallet />
                     <div class="tab-pane fade" id="usd" role="tabpanel" aria-labelledby="tabs-usd-tabJustify">
                        <div class="max-w-xl mx-auto py-10">
                           <div class="border-b border-gray-100 pb-10">
                              <div class="text-3xl text-gray-700 font-semibold">
                                 &#8358;{{ user.wallets.usd_balance | formatPrice }}
                              </div>
                              <p class="text-sm text-gray-400 py-2">Available USD Balance</p>
                           </div>
                           <div class="pt-8">
                              <div class="grid grid-cols-3 gap-5">
                                 <div>
                                    <div class="border rounded py-2 text-center whitespace-nowrap bg-indigo-100 border-indigo-200 hover:bg-indigo-200 hover:border-indigo-300 cursor-pointer">
                                       Bank Account
                                    </div>
                                 </div>
                                 <div>
                                    <div class="border rounded py-2 text-center whitespace-nowrap bg-indigo-100 border-indigo-200 hover:bg-indigo-200 hover:border-indigo-300 cursor-pointer">
                                       Withdraw
                                    </div>
                                 </div>
                                 <div>
                                    <div class="border rounded py-2 text-center whitespace-nowrap bg-indigo-100 border-indigo-200 hover:bg-indigo-200 hover:border-indigo-300 cursor-pointer">
                                       Top Up
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="tab-pane fade" id="cny" role="tabpanel" aria-labelledby="tabs-cny-tabJustify">
                        <div class="h-40 py-10 text-center text-lg mt-5 text-gray-500">Comming Soon...</div>
                     </div>
                     <div class="tab-pane fade" id="crypto" role="tabpanel" aria-labelledby="tabs-crypto-tabJustify">
                        <div class="pt-5">
                           <ul class="nav nav-tabs nav-justified flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0" id="tabs-tabJustify" role="tablist">
                              <li class="nav-item flex-grow text-center" role="presentation">
                                 <a href="#usdt" class="nav-link w-1/2 mx-auto block flex space-x-3 justify-center font-semibold text-sm leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent
                                    px-6 py-4 mt-2 hover:border-transparent hover:text-indigo-700 focus:border-transparent active" id="tabs-ngn-tabJustify" data-bs-toggle="pill" data-bs-target="#usdt" role="tab"
                                    aria-controls="usdt" aria-selected="true">
                                    <img :src="require('@/assets/images/icons/usdt.svg')" alt="" class="w-5 h-5">
                                    <span class="self-center">USDT</span>
                                 </a>
                              </li>
                              <li class="nav-item flex-grow text-center" role="presentation">
                                 <a href="#busd" class="nav-link w-1/2 mx-auto block flex space-x-3 justify-center font-semibold text-sm leading-tight uppercase border-x-0 border-t-0 border-b-2 border-transparent
                                    px-6 py-4 mt-2 hover:border-transparent hover:text-indigo-700 focus:border-transparent" id="tabs-ngn-tabJustify" data-bs-toggle="pill" data-bs-target="#busd" role="tab"
                                    aria-controls="busd" aria-selected="true">
                                    <img :src="require('@/assets/images/icons/busd.svg')" alt="" class="w-5 h-5">
                                    <span class="self-center">BUSD</span>
                                 </a>
                              </li>
                           </ul>
                        </div>
                        <div class="absolute w-full relative">
                           <div class="border-t border-gray-200 p-10 -mt-2 bg-white">
                              <div class="tab-content" id="tabs-tabContentJustify">
                                 <div class="tab-pane fade show active" id="usdt" role="tabpanel" aria-labelledby="tabs-home-tabJustify">
                                    <div class="max-w-full mx-auto py-10">
                                       <div class="border-b border-gray-100 pb-10">
                                          <div class="text-3xl text-gray-700 font-semibold">
                                             {{ user.wallets.usdt_balance }} USDT
                                          </div>
                                          <p class="text-sm text-gray-400 py-2">Available USDT Balance</p>
                                          <div class="mt-5">
                                             <div class="flex justify-start space-x-5">
                                                <div class="font-semibold self-center text-gray-500 whitespace-nowrap">Wallet Addresss:</div>
                                                <div class="text-xl">{{ user.wallets.usdt_address || "******" }}</div>
                                             </div>
                                             <div class="flex justify-start space-x-5">
                                                <div class="font-semibold self-center text-gray-500 whitespace-nowrap">Network:</div>
                                                <div class="text-lg">{{ user.wallets.usdt_network || "******" }}</div>
                                             </div>
                                          </div>
                                          <div class="flex justify-start space-x-5 mt-5" v-if="user.wallets.usdt_address != null && user.wallets.usdt_network != null">
                                             <div>
                                                <div class="border flex justify-center space-x-1 rounded py-1 text-sm px-4 text-center whitespace-nowrap bg-blue-500 text-white hover:bg-blue-600 hover:border-blue-700 border-blue-600 cursor-pointer">
                                                   <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 self-center" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                   </svg>
                                                   <span class="self-center">Edit</span>
                                                </div>
                                             </div>
                                             <div>
                                                <div class="border flex justify-center space-x-1 rounded py-1 text-sm px-4 text-center whitespace-nowrap bg-red-500 text-white hover:bg-red-600 hover:border-red-700 border-red-600 cursor-pointer">
                                                   <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 self-center" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                   </svg>
                                                   <span class="self-center">Delete</span>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="pt-8">
                                          <div class="flex justify-center space-x-5">
                                             <div>
                                                <div class="border rounded py-2 px-4 text-center whitespace-nowrap bg-indigo-100 border-indigo-200 hover:bg-indigo-200 hover:border-indigo-300 cursor-pointer">
                                                   Add Wallet Address
                                                </div>
                                             </div>
                                             <div>
                                                <div class="border rounded py-2 px-4 text-center whitespace-nowrap bg-indigo-100 border-indigo-200 hover:bg-indigo-200 hover:border-indigo-300 cursor-pointer">
                                                   Withdraw
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="tab-pane fade" id="busd" role="tabpanel" aria-labelledby="tabs-profile-tabJustify">
                                    <div class="max-w-full mx-auto py-10">
                                       <div class="border-b border-gray-100 pb-10">
                                          <div class="text-3xl text-gray-700 font-semibold">
                                             {{ user.wallets.busd_balance }} BUSD
                                          </div>
                                          <p class="text-sm text-gray-400 py-2">Available BUSD Balance</p>
                                          <div class="mt-5">
                                             <div class="flex justify-start space-x-5">
                                                <div class="font-semibold self-center text-gray-500 whitespace-nowrap">Wallet Addresss:</div>
                                                <div class="text-xl">{{ user.wallets.busd_address || "******" }}</div>
                                             </div>
                                             <div class="flex justify-start space-x-5">
                                                <div class="font-semibold self-center text-gray-500 whitespace-nowrap">Network:</div>
                                                <div class="text-lg">{{ user.wallets.busd_network || "******" }}</div>
                                             </div>
                                          </div>
                                          <div class="flex justify-start space-x-5 mt-5" v-if="user.wallets.busd_address != null && user.wallets.busd_network != null">
                                             <div>
                                                <div class="border flex justify-center space-x-1 rounded py-1 text-sm px-4 text-center whitespace-nowrap bg-blue-500 text-white hover:bg-blue-600 hover:border-blue-700 border-blue-600 cursor-pointer">
                                                   <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 self-center" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                   </svg>
                                                   <span class="self-center">Edit</span>
                                                </div>
                                             </div>
                                             <div>
                                                <div class="border flex justify-center space-x-1 rounded py-1 text-sm px-4 text-center whitespace-nowrap bg-red-500 text-white hover:bg-red-600 hover:border-red-700 border-red-600 cursor-pointer">
                                                   <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 self-center" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                   </svg>
                                                   <span class="self-center">Delete</span>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="pt-8">
                                          <div class="flex justify-center space-x-5">
                                             <div>
                                                <div class="border rounded py-2 px-4 text-center whitespace-nowrap bg-indigo-100 border-indigo-200 hover:bg-indigo-200 hover:border-indigo-300 cursor-pointer">
                                                   Add Wallet Address
                                                </div>
                                             </div>
                                             <div>
                                                <div class="border rounded py-2 px-4 text-center whitespace-nowrap bg-indigo-100 border-indigo-200 hover:bg-indigo-200 hover:border-indigo-300 cursor-pointer">
                                                   Withdraw
                                                </div>
                                             </div>

                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               
               <div class="flex justify-between my-5">
                  <div class="text-xl font-semibold self-center">{{ selectedWallet | ucwords }} deposit history</div>
                  <div class="relative w-40">
                     <OgCustomSelect :data="transaction_options" :showImage="false" v-model="showTransact" v-on:valueSelect="onTransactSelect"></OgCustomSelect>
                  </div>
               </div>
               <div class="bg-white rounded-lg shadow py-8 mt-5">
                  <template v-if="selectedWallet == 'ngn'">
                     <template v-if="showTransact.value == 'deposit'">
                        <template v-if="user.wallet_transactions.length != 0">
                           <div class="overflow-x-auto w-full">
                              <table class="w-full whitespace-nowrap overflow-hidden">
                                 <thead class="border-b bg-gray-100 uppercase text-sm text-indigo-900">
                                    <tr>
                                       <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                          Transaction Date
                                       </th>
                                       <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                          Transaction ID
                                       </th>
                                       <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                          Amount (&#8358;)
                                       </th>
                                       <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                          Transaction Charge
                                       </th>
                                       <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                          Status
                                       </th>
                                       <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                          Gateway
                                       </th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr class="border-b" v-for="(t, index) in transactions" :key="index">
                                       <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">{{ t.created_at | formatDate }}</td>
                                       <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">{{ t.transaction_ref }}</td>
                                       <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">{{ t.amount | formatPrice }}</td>
                                       <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap uppercase">{{ t.transaction_charge }}</td>
                                       <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">
                                          <span class="px-3 py-1.5 bg-indigo-200 text-xs uppercase rounded-full cursor-pointer" v-if="t.status == 'pending'">{{ t.status | capitalize }}</span>
                                          <span class="px-3 py-1.5 bg-green-300 text-xs uppercase rounded-full cursor-pointer" v-else-if="t.status == 'success'">{{ t.status | capitalize }}</span>
                                          <span class="px-3 py-1.5 bg-red-200 text-xs uppercase rounded-full cursor-pointer" v-else-if="t.status == 'failed'">{{ t.status | capitalize }}</span>
                                          <span class="px-3 py-1.5 bg-yellow-200 text-xs uppercase rounded-full cursor-pointer" v-else-if="t.status == 'disputed'">{{ t.status | capitalize }}</span>
                                          <span class="px-3 py-1.5 bg-gray-300 text-xs uppercase rounded-full cursor-pointer" v-else>{{ t.status | capitalize }}</span>
                                       </td>
                                       <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">{{ t.gateway | capitalize }}</td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </template>
                        <template v-else>
                           <section class="grid place-content-center py-24">
                              <div>
                                 <svg xmlns="http://www.w3.org/2000/svg" class="w-14 h-14 mx-auto text-gray-200" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                 <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                              </svg>
                              <p class="text-lg text-gray-400 py-5">{{ selectedWallet | ucwords }} top-up history is empty.</p>
                              </div>
                           </section>
                        </template>
                     </template>
                     <template v-if="showTransact.value == 'withdrawal'">
                        <template v-if="user.wallet_transactions.length != 0">
                           <div class="overflow-x-auto w-full">
                              <table class="w-full whitespace-nowrap overflow-hidden">
                                 <thead class="border-b bg-gray-100 uppercase text-sm text-indigo-900">
                                    <tr>
                                       <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                          Transaction Date
                                       </th>
                                       <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                          Transaction ID
                                       </th>
                                       <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                          Amount (&#8358;)
                                       </th>
                                       <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                          Transaction Charge
                                       </th>
                                       <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                          Status
                                       </th>
                                       <th scope="col" class="text-sm font-medium px-6 py-4 text-left">
                                          Gateway
                                       </th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr class="border-b" v-for="(t, index) in transactions" :key="index">
                                       <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">{{ t.created_at | formatDate }}</td>
                                       <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">{{ t.transaction_ref }}</td>
                                       <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">{{ t.amount | formatPrice }}</td>
                                       <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap uppercase">{{ t.transaction_charge }}</td>
                                       <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">
                                          <span class="px-3 py-1.5 bg-indigo-200 text-xs uppercase rounded-full cursor-pointer" v-if="t.status == 'pending'">{{ t.status | capitalize }}</span>
                                          <span class="px-3 py-1.5 bg-green-300 text-xs uppercase rounded-full cursor-pointer" v-else-if="t.status == 'success'">{{ t.status | capitalize }}</span>
                                          <span class="px-3 py-1.5 bg-red-200 text-xs uppercase rounded-full cursor-pointer" v-else-if="t.status == 'failed'">{{ t.status | capitalize }}</span>
                                          <span class="px-3 py-1.5 bg-yellow-200 text-xs uppercase rounded-full cursor-pointer" v-else-if="t.status == 'disputed'">{{ t.status | capitalize }}</span>
                                          <span class="px-3 py-1.5 bg-gray-300 text-xs uppercase rounded-full cursor-pointer" v-else>{{ t.status | capitalize }}</span>
                                       </td>
                                       <td class="text-sm text-gray-600 font-medium px-6 py-4 whitespace-nowrap">{{ t.gateway | capitalize }}</td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </template>
                        <template v-else>
                           <section class="grid place-content-center py-24">
                              <div>
                                 <svg xmlns="http://www.w3.org/2000/svg" class="w-14 h-14 mx-auto text-gray-200" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                 </svg>
                                 <p class="text-lg text-gray-400 py-5">{{ selectedWallet | ucwords }} withdrawal history is empty.</p>
                              </div>
                           </section>
                        </template>
                     </template>
                  </template>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>


<script>
import { mapGetters } from "vuex";
import NgnWallet from './components/ngn/ngn_wallet.vue'
import OgCustomSelect from '@/components/og-custom-select.vue'
import _ from 'lodash';

export default {
   name: 'Wallets',

   components: {
      NgnWallet,
      OgCustomSelect
   },

   data() {
      return {
         isLoading: false,
         selectedWallet: 'ngn',
         showTransact: { name: "Deposit", value: 'deposit' },
         transaction_options: [
            { name: "Deposit", value: 'deposit' },
            { name: "Withdrawal", value: 'withdrawal' },
         ],
         transactions: []
      }
   },
   
   methods: {
      onTransactSelect(value) {
         this.showTransact = value
         this.filterTransactions()
      },

      filterTransactions() {
         this.transactions = _.filter(this.user.wallet_transactions, {'transaction_type': this.showTransact.value, 'wallet': this.selectedWallet.toUpperCase()})
      }

   },
   
   computed: {
      ...mapGetters("auth", ["user"]),
   },

   mounted() {
      this.filterTransactions()
   }
}
</script>